@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.u-button {
  background: #000;
  border: 1px solid black;
  box-sizing: border-box;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  margin-top: 3vh;
  padding: 1.5vh 0;
  text-transform: uppercase;
}

.u-button-disabled {
  background: #fff;
  border: 1px solid black;
  color: #000;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.event-sessions a, .event-sessions a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer !important;
}

button:focus {
  outline: none !important;
}