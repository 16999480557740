.web-SmsSent-container {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0 2em 2em 2em;
  text-align: center;
  width: 400px;
}

.web-SmsSent-icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.web-SmsSent-icon div {
  background-color: black;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  left: 12px;
  padding: 3px;
  position: relative;
  top: 10px;
  width: 20px;
}

.web-SmsSent-title {
  align-self: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 2vh;
  width: 100%;
}

.web-SmsSent-subtitle {
  font-size: 16px;
  line-height: 22px;
  margin-top: 2vh;
  width: 100%;
}

.web-SmsSent-copyright {
  color: #808589;
  font-size: 11px;
  line-height: 13px;
  margin-top: 1vh;
}

.web-SmsSent-concierge {
  padding-left: 5px;
}

@media only screen and (max-width: 900px) {
  .web-SmsSent {
    align-items: start;
  }

  .web-SmsSent-container {
    box-shadow: none;
    width: 100vw;
  }

  .web-SmsSent-title {
    width: 100%;
  }
}
