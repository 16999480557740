.web-StripeElements {
  align-items: center;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
}

.web-StripeElements-title {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}

.web-StripeElements-subtitle {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 22px;
  text-align: center;
}

.web-StripeElements-safe {
  color: #767676;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
}

.web-StripeElements-cardholder {
  border: none;
  width: 100%;
}

.web-StripeElements-error {
  color: #b00020;
  font-size: 14px;
  text-align: left;
}

.web-StripeElements-errors {
  grid-column: 1/3;
}

.web-StripeElements-name {
  border: 2px solid #e4e5e6;
  font-size: 18px;
  padding: 5px;
  width: 100%;
}

.web-StripeElements-icon-container {
  border: 2px solid #e4e5e6;
  display: flex;
  flex-direction: row;
}

.web-StripeElements-input {
  font-size: 18px;
  padding: 5px;
  width: 100%;
}

.web-StripeElements-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
