.web-SmsForm-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 0;
  width: 400px;
  max-height: 60vh;
  overflow-y: auto;
  clip-path: inset(0 round 8px);
}

.web-SmsForm-title {
  align-self: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 2vh;
  width: 100%;
}

.web-SmsForm-subtitle {
  font-size: 16px;
  line-height: 22px;
  margin-top: 2vh;
  width: 100%;
}

.web-SmsForm-input-container {
  background: #fff;
  border: 2px solid #e4e5e6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 18px;
  margin-top: 1em;
}

.web-SmsForm-icon {
  padding: 0 1em;
}

.web-SmsForm-input {
  border: none;
  padding: 10px 10px;
  width: 100%;
}

.web-SmsForm-error {
  color: red;
}

.web-SmsForm-disclaimer {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 12px;
}

.web-SmsForm-checkbox {
  margin-top: 20px;
}

.web-SmsForm-checkbox .w-checkbox-text {
  text-wrap: wrap;
  text-align: start;
  padding: 0px 15px;
}
.web-SmsForm-checkbox input {
  vertical-align: top !important;
}

@media only screen and (max-width: 900px) {
  .web-SmsForm {
    align-items: start;
  }

  .web-SmsForm-container {
    box-shadow: none;
    width: 100vw;
    overflow-y: visible;
    clip-path: none;
  }

  .web-SmsForm-title {
    width: 100%;
  }
}
