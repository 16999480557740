.web-PoweredByConcierge-copyright {
  align-items: flex-end;
  color: #808589;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  justify-content: center;
  line-height: 13px;
  margin: 0 -1.25rem;
}

.web-PoweredByConcierge-concierge {
  padding-left: 5px;
}
