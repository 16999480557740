.web-CreditCard {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-family: Helvetica;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .web-CreditCard {
    align-items: start;
  }
}
