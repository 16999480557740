.web-Logo {
  display: flex;
  justify-content: center;

  width: inherit;
  min-height: 100px;
  border-bottom: 1px solid #e4e5e6;
}

.web-Logo-img {
  margin: 24px;
  max-width: -webkit-fill-available;
  object-fit: scale-down;
}