.web-CreditCardError {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0 2em 2em 2em;
  text-align: center;
  width: 400px;
}

@media only screen and (max-width: 900px) {
  .web-CreditCardError {
    box-shadow: none;
    width: 100vw;
  }
}
