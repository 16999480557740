.web-CreditCardConfirmation {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0 2em 2em 2em;
  text-align: center;
  width: 400px;
}

.web-CreditCardConfirmation-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 7px;
}

.web-CreditCardConfirmation-subtitle {
  font-size: 16px;
  line-height: 22px;
}

@media only screen and (max-width: 900px) {
  .web-CreditCardConfirmation {
    box-shadow: none;
    width: 100vw;
  }
}
